import { useNavigate } from 'react-router-dom';
const BackToButton = ({ link, array = null, currEl = null }) => {
  const navigate = useNavigate();
  const currIndex = array?.findIndex((el) => el.id === currEl.id);
  const prevEl = array?.find((el, index) => currIndex - 1 === index);
  return (
    <>
      {!array && !currEl && (
        <button className="button" id="back-to" onClick={(e) => navigate(link)}>
          Назад
        </button>
      )}
      {array && currEl && (
        <button
          className="button"
          id="back-to"
          onClick={(e) => navigate(`${link}/${prevEl?.id ? prevEl?.id : ''}`)}
        >
          Назад
        </button>
      )}
    </>
  );
};

export default BackToButton;
