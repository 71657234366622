import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTE } from '../utils/consts';
import AddNewForm from './pages split components/AdminControllers/AdminNewsControllerPage/AddNewForm';
import DeleteNewForm from './pages split components/AdminControllers/AdminNewsControllerPage/DeleteNewForm';
import ChangeNewForm from './pages split components/AdminControllers/AdminNewsControllerPage/ChangeNewForm';
import BackToButton from '../components/Buttons/BackToButton';
const AdminNewsControllerPage = () => {
  const [selectedOption, setSelectedOption] = useState(0);

  return (
    <div className="admin-news-controller-container controller-container page-container">
      <BackToButton link={ADMIN_ROUTE} />
      <div>
        <form>
          <select
            id="select-input"
            onChange={(e) => {
              setSelectedOption(e.target.selectedIndex);
            }}
          >
            <option defaultValue id="add-new">
              Добавить Новость
            </option>
            <option id="delete-new">Удалить Новость</option>
            <option id="change-new">Изменить Новость</option>
          </select>
          {selectedOption == 0 ? (
            <AddNewForm />
          ) : selectedOption == 1 ? (
            <DeleteNewForm />
          ) : (
            <ChangeNewForm />
          )}
        </form>
      </div>
    </div>
  );
};
export default AdminNewsControllerPage;
// e.target.options[e.target.selectedIndex].id
