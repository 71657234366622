import { useContext, useState } from 'react';
import { Context } from '../../../..';
const ChangeHelpfulForm = () => {
  const { helpful } = useContext(Context);
  const [selectedId, setSelectedId] = useState('...');
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const selectedIdHandler = (e) => {
    setSelectedId(e.target.options[e.target.selectedIndex].id);
    if (e.target.options[e.target.selectedIndex].id != '...') {
      setIsSelected(true);
    } else {
      setIsSelected(false);
      clearInputs();
    }
  };
  const clearInputs = () => {
    setName('');
    setLink('');
  };
  const fillInputs = (e) => {
    e.preventDefault();
    const currHelpful = helpful._news.find((el) => el.id == selectedId);
    setName(currHelpful?.name);
    setLink(currHelpful?.link);
  };
  const changeNew = (e) => {
    e.preventDefault();
    helpful.changeNew(selectedId, name, link);
  };
  return (
    <>
      <h2>Изменить Ссылку</h2>
      <select id="select-input" onChange={selectedIdHandler}>
        <option defaultValue id="...">
          ...
        </option>
        {helpful._news.map((el) => {
          return (
            <option key={el.id} id={el.id}>
              {`${el.name.slice(0, 45)}`}...
            </option>
          );
        })}
      </select>
      <button className="button" disabled={!isSelected} onClick={fillInputs}>
        Заполнить старый текст
      </button>
      <h3>Поля</h3>

      <input
        type="text"
        placeholder="Введите новое название..."
        value={name}
        onChange={(e) => setName(e.target.value)}
        id="title-input"
        disabled={!isSelected}
      />
      <input
        type="text"
        placeholder="Введите новую ссылку..."
        value={link}
        onChange={(e) => setLink(e.target.value)}
        id="title-input"
        disabled={!isSelected}
      />
      <button className="button" disabled={!isSelected} onClick={changeNew}>
        Изменить
      </button>
    </>
  );
};

export default ChangeHelpfulForm;
