import Item from './Item';

const Items = ({ currentItems }) => {
  return (
    <>
      {currentItems &&
        currentItems.map(({ question, answer }) => (
          <Item key={`${question}`} question={question} answer={answer} />
        ))}
    </>
  );
};

export default Items;
