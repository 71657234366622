import { useContext, useState } from 'react';
import { Context } from '../../../../';
import getNowDate, {
  formatDateTypeTo,
} from '../../../../components/modals/getDateFunc';

const ChangeNewForm = () => {
  const { news } = useContext(Context);
  const [selectedId, setSelectedId] = useState('...');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [date, setDate] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const selectedIdHandler = (e) => {
    setSelectedId(e.target.options[e.target.selectedIndex].id);
    if (e.target.options[e.target.selectedIndex].id != '...') {
      setIsSelected(true);
    } else {
      setIsSelected(false);
      clearInputs();
    }
  };
  const clearInputs = () => {
    setTitle('');
    setText('');
  };
  const fillInputs = (e) => {
    e.preventDefault();
    const currNew = news._news.find((el) => el.id == selectedId);
    setTitle(currNew?.title);
    setText(currNew?.text);
    setDate(formatDateTypeTo(currNew?.date, 2));
  };
  const changeNew = (e) => {
    e.preventDefault();
    news.changeNew(selectedId, title, text, date);
  };
  return (
    <>
      <h2>Изменить Новость</h2>
      <select id="select-input" onChange={selectedIdHandler}>
        <option defaultValue id="...">
          ...
        </option>
        {news._news.map((el) => {
          return (
            <option key={el.id} id={el.id}>
              {`(${el.date}) ${el.title.slice(0, 50)}`}...
            </option>
          );
        })}
      </select>
      <button className="button" disabled={!isSelected} onClick={fillInputs}>
        Заполнить старый текст
      </button>
      <h3>Инфо</h3>
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        id="date-input"
        disabled={!isSelected}
      />
      <input
        type="text"
        placeholder="Введите новый заголовок..."
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        id="title-input"
        disabled={!isSelected}
      />
      <textarea
        placeholder="Введите новый текст..."
        value={text}
        onChange={(e) => setText(e.target.value)}
        id="text-input"
        disabled={!isSelected}
      />
      <button className="button" disabled={!isSelected} onClick={changeNew}>
        Изменить
      </button>
    </>
  );
};

export default ChangeNewForm;
