export default function getNowDate(date = new Date()) {
  const nowMonth =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const nowMonth2 =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const nowDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const nowDay2 = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const nowYear = date.getFullYear();
  const nowYear2 = date.getFullYear();
  const nowDateType1 = `${nowYear}-${nowMonth}-${nowDay}`;
  const nowDateType2 = `${nowDay2}.${nowMonth2}.${nowYear2}`;
  return {
    nowDateType1: nowDateType1,
    nowDateType2: nowDateType2,
  };
}
export function formatDateTypeTo(dateStr, currType) {
  let returningDateStr;
  if (currType == 1) {
    const formatedArr = dateStr.split('-');
    returningDateStr = `${formatedArr[2]}.${formatedArr[1]}.${formatedArr[0]}`;
  } else {
    const formatedArr = dateStr.split('.');
    returningDateStr = `${formatedArr[2]}-${formatedArr[1]}-${formatedArr[0]}`;
  }
  return returningDateStr;
}

//   const nowHour =
//     new Date().getHours() < 10
//       ? '0' + new Date().getHours()
//       : new Date().getHours();
//   const nowMinute =
//     new Date().getMinutes() < 10
//       ? '0' + new Date().getMinutes()
//       : new Date().getMinutes();
//   const nowSeconds =
//     new Date().getSeconds() < 10
//       ? '0' + new Date().getSeconds()
//       : new Date().getSeconds();

//   const nowDate = `${nowDay}.${nowMonth}|${nowHour}:${nowMinute}:${nowSeconds}`;
