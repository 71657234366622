import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import Items from './Items';
// import SearchedItems from './SearchedItems';
import { urhelpPageList } from '../../../utils/consts';
const PaginatedItems = ({ itemsPerPage }) => {
  const [itemOffset, setItemOffset] = useState(0);
  // const [searchTerm, setSearchTerm] = useState('');
  // const [searchResults, setSearchResults] = useState('');
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(urhelpPageList.length / itemsPerPage);
  let currentItems = urhelpPageList.slice(itemOffset, endOffset);

  // const handleChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };
  // const searchBtnHandler = (e) => {
  //   e.preventDefault();
  //   const results = urhelpPageList.filter(({ question }) =>
  //     question.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   if (results) setSearchResults(results);
  // };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % urhelpPageList.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <div className="search-block">
        {/* <form className="search-input-block" onSubmit={searchBtnHandler}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleChange}
            id="search-input"
          />
          <button type="submit">поиск</button>
        </form> */}
        <div className="items-list">
          <Items currentItems={currentItems} />
        </div>
      </div>
      <div className="pagination-block">
        <ReactPaginate
          breakLabel="..."
          nextLabel="дальше >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< назад"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default PaginatedItems;

//   useEffect(() => {
//     const results = urhelpPageList.filter((person) =>
//       person.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setSearchResults(results);
//   }, [searchTerm]);
