import { useContext, useState } from 'react';
import { Context } from '../../../../';
import getNowDate from '../../../../components/modals/getDateFunc';
import imageCompression from 'browser-image-compression';
import { observer } from 'mobx-react-lite';
const AddNewForm = () => {
  const { news } = useContext(Context);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [date, setDate] = useState(getNowDate().nowDateType1);
  const [imagesUpload, setImageUpload] = useState([]);

  const addNew = async (e) => {
    e.preventDefault();
    if (
      // imagesUpload !== [] &&
      title !== '' &&
      text !== '' &&
      date !== '' &&
      date !== null
    ) {
      const compressedImages = await compressImages(imagesUpload);
      news.setIsUploading(true);
      Promise.all(compressedImages)
        .then((blobImg) => news.uploadFile(title, text, date, blobImg))
        .catch((reason) => {
          alert('Что-то пошло не так!');
          news._isUploading(false);
        });
    } else {
      alert('Не все данные заполнены!');
    }
  };
  const compressImages = async (imagesArr) => {
    const options = {
      maxSizeMB: 0.50,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const returningArr = Array.from(imagesArr).map(async (imageFile) => {
        return await imageCompression(imageFile, options);
      });
      return returningArr;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <h2>Добавить Новость</h2>
      {news._isUploading && (
        <h3 className="waiting">Ждите, новость добавляется...</h3>
      )}
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        id="date-input"
        maxLength="10"
        minLength="10"
        disabled={news._isUploading}
      />
      <input
        type="text"
        placeholder="Введите заголовок..."
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        id="title-input"
        disabled={news._isUploading}
      />
      <textarea
        placeholder="Введите текст..."
        value={text}
        onChange={async (e) => setText(e.target.value)}
        id="text-input"
        disabled={news._isUploading}
      />
      <input
        type="file"
        accept="images/*"
        multiple
        onChange={(event) => {
          setImageUpload(event.target.files);
        }}
        disabled={news._isUploading}
      />
      <button onClick={addNew} className="button" disabled={news._isUploading}>
        Добавить
      </button>
    </>
  );
};

export default observer(AddNewForm);
