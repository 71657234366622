// import CreateGalleryItemModal from '../components/modals/CreateGalleryItemModal';
// import CreateWorkerModal from '../components/modals/CreateWorkerModal';
// import CreateNewModal from '../components/modals/CreateNewModal';
// import { useState } from 'react';
import './css/AdminPage.css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
  ADMIN_HELPFUL_CONTROLLER_ROUTE,
  ADMIN_NEWS_CONTROLLER_ROUTE,
  ADMIN_SLIDER_CONTROLLER_ROUTE,
} from '../utils/consts';
const AdminPage = () => {
  // const [showNew, setShowNew] = useState(false);
  // const [showWorker, setShowWorker] = useState(false);
  // const [showGallery, setShowGallery] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="admin-container page-container">
      <h2>Админ панель</h2>
      <button
        className="button"
        onClick={() => navigate(ADMIN_NEWS_CONTROLLER_ROUTE)}
      >
        Управление: Новости
      </button>
      <button
        className="button"
        onClick={() => navigate(ADMIN_SLIDER_CONTROLLER_ROUTE)}
      >
        Управление: Слайдер
      </button>
      <button
        className="button"
        onClick={() => navigate(ADMIN_HELPFUL_CONTROLLER_ROUTE)}
      >
        Управление: Полезная Информация
      </button>
    </div>
  );
};
export default observer(AdminPage);

// <button onClick={() => setShowNew(true)}>Управление: Новость</button>
// <button onClick={() => setShowWorker(true)}>Управление: сотрудник</button>
// <button onClick={() => setShowGallery(true)}>
//   Добавить Фото в галерею
// </button>
// <CreateNewModal onClose={() => setShowNew(false)} show={showNew} />
// <CreateWorkerModal
//   onClose={() => setShowWorker(false)}
//   show={showWorker}
// />
// <CreateGalleryItemModal
//   onClose={() => setShowGallery(false)}
//   show={showGallery}
// />
