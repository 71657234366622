import { useNavigate } from 'react-router-dom';
import { NEWS_ROUTE } from '../../../utils/consts';
import './css/NewCard.css';
const NewCard = (props) => {
  const navigate = useNavigate();
  const cardClickHandler = (id) => {
    navigate(NEWS_ROUTE + '/' + id);
  };
  return (
    <div
      className="new-card"
      onClick={() => {
        cardClickHandler(props.id);
      }}
    >
      <div className="img-card-date item">
        <div className="img">
          {Boolean(props?.img?.length) && <img src={props.img} />}
          {!Boolean(props?.img?.length) && (
            <div className="no-img">Нет изображения</div>
          )}
        </div>
        <div>{props.date}</div>
      </div>
      <div className="title item">{props.title}...</div>
    </div>
  );
};

// <img src={require(`../../../imgs/bdimgs/news/${props.img}`)}
export default NewCard;
