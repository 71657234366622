import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTE } from '../utils/consts';
import AddSliderForm from './pages split components/AdminControllers/AdminSliderController/AddSliderForm';
import DeleteSliderForm from './pages split components/AdminControllers/AdminSliderController/DeleteSliderForm';
import ChangeSliderForm from './pages split components/AdminControllers/AdminSliderController/ChangeSliderForm';
import BackToButton from '../components/Buttons/BackToButton';
const AdminSliderControllerPage = () => {
  const [selectedOption, setSelectedOption] = useState(0);
  return (
    <div className="admin-slider-controller-container controller-container page-container">
      <BackToButton link={ADMIN_ROUTE} />
      <div>
        <form>
          <select
            id="select-input"
            onChange={(e) => {
              setSelectedOption(e.target.selectedIndex);
            }}
          >
            <option defaultValue id="add-new">
              Добавить Слайд
            </option>
            <option id="delete-new">Удалить Слайд</option>
            <option id="change-new">Изменить Слайд</option>
          </select>
          {selectedOption == 0 ? (
            <AddSliderForm />
          ) : selectedOption == 1 ? (
            <DeleteSliderForm />
          ) : (
            <ChangeSliderForm />
          )}
        </form>
      </div>
    </div>
  );
};

export default AdminSliderControllerPage;
