import { useContext, useState } from 'react';
import { Context } from '../index';
import NewCard from './pages split components/News/NewCard';
import './css/NewsPage.css';
import { observer } from 'mobx-react-lite';
const News = () => {
  const [currentCount, setCurrentCount] = useState(9)
  const [isDisabled,setIsDisabled] = useState(false)
  const { news } = useContext(Context);

  const moreBtnHandler = (e)=>{
    e.preventDefault()
    currentCount <= news._news.length && setCurrentCount((val)=>val+=9)
    currentCount >= news._news.length && setIsDisabled(true)
    
  }
  if (!Boolean(news._news.length)) {
    return (
      <div className="page-container">
        <h1>Новости</h1>
        <br />
        <h2>Не удалось загрузить новости!</h2>
      </div>
    );
  }
  return (
    <div className="news-page-container page-container">
      <h1>Новости</h1>
      <div className="news-collection">
        {news._news?.map((newO,index) => (
          index < currentCount && 
          <NewCard
            key={`${newO.title + newO.id}`}
            img={newO.img[0]}
            title={newO.title}
            text={newO.text}
            date={newO.date}
            id={newO.id}
          />
        ))}
      </div>
      <div id='btn-block'>
      <button onClick={moreBtnHandler} disabled={isDisabled} className='load-more-button button'>Еще...</button>
      </div>
    </div>
  );
};

export default observer(News);
