import {
  collection,
  getDocs,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { makeAutoObservable } from 'mobx';
import { app, db, storage } from '../../config/firebaseConfig';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { v4 } from 'uuid';

export default class SliderStore {
  constructor() {
    this._slider = [];
    this._urls = [];
    this._isSliderLoading = true;
    this._sliderTypes = [];
    makeAutoObservable(this);
  }
  get slider() {
    return this._slider;
  }
  get urls() {
    return this._urls;
  }
  get isSliderLoading() {
    return this._isSliderLoading;
  }
  setSlider(slider) {
    this._slider = slider;
  }
  setUrls(urls) {
    this._urls = urls;
  }
  setIsSliderLoading(bool) {
    this._isSliderLoading = bool;
  }
  setSliderTypes() {
    this._sliderTypes = Array.from(new Set(this._slider.map((el) => el.type)));
  }
  async getAllSlides() {
    try {
      const sliderCol = collection(db, 'slider');
      const sliderSnapshot = await getDocs(sliderCol);
      const sliderList = sliderSnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      this.setSlider(
        sliderList.sort((a, b) => a.createdAt - b.createdAt).reverse()
      );
      this.setSliderTypes();
      this.setIsSliderLoading(false);
      return sliderList;
    } catch (e) {
      console.log(e);
    }
  }
  async uploadFile(text, imageUpload, hasButton, buttonUrl, type) {
    if (
      imageUpload === null ||
      text === [] ||
      typeof hasButton !== 'boolean' ||
      type === '...'
    ) {
      alert('Не все данные заполнены!');
      return;
    }
    try {
      const imageRef = ref(storage, `images/slider/${v4() + imageUpload.name}`);
      const snapshot = await uploadBytes(imageRef, imageUpload);
      const url = await getDownloadURL(snapshot.ref);
      this.setSlide({
        text,
        img: url,
        hasButton: hasButton,
        buttonUrl: buttonUrl,
        pathToImg: imageRef.fullPath,
        type: type,
      }).then((data) => alert('Слайд успешно добавлена!'));
      this.setIsSliderLoading(false);
    } catch (e) {
      alert('Не удалось добавить слайд!');
    }
  }
  async setSlide({ text, img, pathToImg, hasButton, buttonUrl, type }) {
    await addDoc(collection(db, 'slider'), {
      text: text,
      img: img,
      pathToImg: pathToImg,
      hasButton: hasButton,
      buttonUrl: buttonUrl,
      type: type,
      createdAt: Date.now(),
    });
    await this.getAllSlides();
  }
  async deleteSlide(id) {
    if (id === '...') {
      alert('id слайда не выбран!');
      return;
    }
    try {
      const deletingSlider = this._slider.find((el) => el.id === id);
      const imgRef = ref(storage, deletingSlider.pathToImg);
      deleteObject(imgRef).catch((error) => {
        alert('Не удалось удалить слайд!');
        return;
      });
      await deleteDoc(doc(db, 'slider', id));
      alert('Слайд удален успешно!');
      await this.getAllSlides();
    } catch (e) {
      alert('Не удалось удалить слайд!');
    }
  }
  async changeSlide(id, text, hasButton, buttonUrl) {
    if (id === '...' || text === [] || typeof hasButton !== 'boolean') {
      alert('Не все данные введены!');
      return;
    }
    try {
      const updatingNewRef = doc(db, 'slider', id);
      await updateDoc(updatingNewRef, {
        text: text,
        hasButton: hasButton,
        buttonUrl: buttonUrl,
      });
      await this.getAllSlides();
      alert('Слайд изменена успешно!');
    } catch (error) {
      alert('Не удалось изменить слайд!');
    }
  }
}
