const SliderHeroType = ({ text, img, title, hasButton, buttonUrl }) => {
  return (
    <>
      <img src={img} alt="Не удалось загрузить изображение!" id="hero-img" />
      <div className="text">
        <h2>
          слава героям
          <br />
          россии!
        </h2>
        <img
          src={require('../../../../imgs/bdImgs/slider/border/border1.png')}
          id="border"
          alt="Не удалось загрузить изображение!"
        />
        <div className="paragraph">
          {text.map((el, index) => (
            <p key={index}>{el}</p>
          ))}
        </div>
        {hasButton && (
          <a href={buttonUrl} className="button">
            Подробнее
          </a>
        )}
      </div>
    </>
  );
};

export default SliderHeroType;
