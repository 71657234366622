import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Context } from '.';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const { news, user, slider, helpful } = useContext(Context);

  useEffect(() => {
    news.getAllNews();
    user.checkAuth();
    slider.getAllSlides();
    helpful.getAllNews();
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRouter />
    </BrowserRouter>
  );
}

export default observer(App);
