import { useContext } from 'react';
import { Context } from '../../..';
const HelpfulInfoMainPage = () => {
  const { helpful } = useContext(Context);

  return (
    <div className="helpful-info container">
      <h2>полезная информация</h2>
      <div className="helpful-list">
        <ul>
          {helpful._news.map(({ name, link }) => {
            return (
              <li key={name}>
                <a href={link} target="_blank">
                  {name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default HelpfulInfoMainPage;
