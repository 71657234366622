import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../..';
import NewCard from '../News/NewCard';
import { NEWS_ROUTE } from '../../../utils/consts';
import { observer } from 'mobx-react-lite';

const NewsMainPage = () => {
  const { news } = useContext(Context);
  const navigate = useNavigate();
  return (
    <div className="news container">
      <h2>новости</h2>
      <div className="content">
        <div className="news-collection">
          {news._news?.map(
            (newO, index) =>
              index < 3 && (
                <NewCard
                  key={`${newO.title}_${Math.random()}`}
                  img={newO.img}
                  title={newO.title}
                  date={newO.date}
                  id={newO.id}
                />
              )
          )}
        </div>
        <button
          onClick={(e) => navigate(NEWS_ROUTE)}
          className="button news-button"
        >
          Все новости
        </button>
      </div>
    </div>
  );
};

export default observer(NewsMainPage);
