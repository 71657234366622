const Item = ({ question, answer }) => {
  return (
    <div className="item">
      <div id="question">{question}</div>
      <div id="answer">{answer}</div>
    </div>
  );
};

export default Item;
