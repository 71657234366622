import { ABOUT_COMPANY_TEXT } from '../../../utils/consts';
import { Link } from 'react-router-dom';
import { INFORMATION_ROUTE } from '../../../utils/consts';
const AboutCompanyMainPage = () => {
  return (
    <div className="about container">
      <h1>Совет Ветеранов г. Кинешма</h1>
      <p>
        <Link to={INFORMATION_ROUTE}>{ABOUT_COMPANY_TEXT}</Link>
      </p>
    </div>
  );
};

export default AboutCompanyMainPage;
