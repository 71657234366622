import './css/Urhelp.css';
import PaginatedItems from './pages split components/Urhelp/PaginatedItems';

const Urhelp = () => {
  return (
    <div className="urhelp-page-container page-container">
      <h2>Юридическая помощь</h2>
      <PaginatedItems itemsPerPage={6} />
    </div>
  );
};
export default Urhelp;
