import Information from './pages/Information';
import Documents from './pages/Documents';
import Urhelp from './pages/Urhelp';
import Recommendations from './pages/Recommendations';
import News from './pages/News';
import Gallery from './pages/Gallery';
import Contacts from './pages/Contacts';
import AdminPage from './pages/AdminPage';
import {
  ADMIN_HELPFUL_CONTROLLER_ROUTE,
  ADMIN_LOGIN_ROUTE,
  ADMIN_NEWS_CONTROLLER_ROUTE,
  ADMIN_ROUTE,
  ADMIN_SLIDER_CONTROLLER_ROUTE,
  CONTACTS_ROUTE,
  DOCUMENTS_ROUTE,
  GALLERY_ROUTE,
  INFORMATION_ROUTE,
  NEWS_ROUTE,
  RECOMMENDATIONS_ROUTE,
  URHELP_ROUTE,
} from './utils/consts';
import AdminLoginPage from './pages/AdminLoginPage';
import NewPage from './pages/singlepages/NewPage';
import DocumentPage from './pages/singlepages/DocumentPage';
import GalleryItemPage from './pages/singlepages/GalleryItemPage';
import AdminNewsControllerPage from './pages/AdminNewsControllerPage';
import AdminSliderControllerPage from './pages/AdminSliderControllerPage';
import AdminHelpfulControllerPage from './pages/AdminHelpfulController';

export const authRouters = [
  {
    path: ADMIN_ROUTE,
    Component: <AdminPage />,
  },
  {
    path: ADMIN_NEWS_CONTROLLER_ROUTE,
    Component: <AdminNewsControllerPage />,
  },
  {
    path: ADMIN_SLIDER_CONTROLLER_ROUTE,
    Component: <AdminSliderControllerPage />,
  },
  {
    path: ADMIN_HELPFUL_CONTROLLER_ROUTE,
    Component: <AdminHelpfulControllerPage />,
  },
];
export const routers = [
  {
    path: INFORMATION_ROUTE,
    Component: <Information />,
  },
  {
    path: DOCUMENTS_ROUTE,
    Component: <Documents />,
  },
  {
    path: DOCUMENTS_ROUTE + '/:id',
    Component: <DocumentPage />,
  },
  {
    path: URHELP_ROUTE,
    Component: <Urhelp />,
  },
  {
    path: RECOMMENDATIONS_ROUTE,
    Component: <Recommendations />,
  },
  {
    path: NEWS_ROUTE,
    Component: <News />,
  },
  {
    path: NEWS_ROUTE + '/:id',
    Component: <NewPage />,
  },
  {
    path: GALLERY_ROUTE,
    Component: <Gallery />,
  },
  {
    path: GALLERY_ROUTE + '/:id',
    Component: <GalleryItemPage />,
  },
  {
    path: CONTACTS_ROUTE,
    Component: <Contacts />,
  },
  {
    path: ADMIN_LOGIN_ROUTE,
    Component: <AdminLoginPage />,
  },
];
