import { useContext, useState } from 'react';
import { Context } from '../../../..';
import { observer } from 'mobx-react-lite';
const AddHelpfulForm = () => {
  const { helpful } = useContext(Context);
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const addNew = async (e) => {
    e.preventDefault();
    if (name !== '' && link !== '') {
      if (helpful._news.length === 10) {
        alert('Превышено макс. кол-во ссылок!');
        return;
      } else {
        helpful.setIsUploading(true);
        helpful.uploadFile(name, link);
      }
    } else {
      alert('Не все данные заполнены!');
    }
  };

  return (
    <>
      <h2>Добавить Ссылку</h2>
      <h4 className="subheading">Максимальное кол-во ссылок: 10</h4>
      <h4 className="subheading2">
        Текущее кол-во ссылок: {helpful._news.length}
      </h4>
      {helpful._isUploading && (
        <h3 className="waiting">Ждите, ссылка добавляется...</h3>
      )}

      <input
        type="text"
        placeholder="Введите название..."
        value={name}
        onChange={(e) => setName(e.target.value)}
        id="title-input"
        disabled={helpful._isUploading}
      />

      <input
        type="text"
        placeholder="Введите ссылку..."
        value={link}
        onChange={(e) => setLink(e.target.value)}
        id="title-input"
        disabled={helpful._isUploading}
      />

      <button
        onClick={addNew}
        className="button"
        disabled={helpful._isUploading}
      >
        Добавить
      </button>
    </>
  );
};

export default observer(AddHelpfulForm);
