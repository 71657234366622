// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB_tCgfIp_klRlIr_LTpvZ11z92K7xnntc',
  authDomain: 'sovetveteranovsite.firebaseapp.com',
  projectId: 'sovetveteranovsite',
  storageBucket: 'sovetveteranovsite.appspot.com',
  messagingSenderId: '796139072974',
  appId: '1:796139072974:web:e01491ff945a68a2e38f9f',
  measurementId: 'G-CM8GFMQSZQ',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();
