import {
  CONTACT_ADRESS,
  CONTACT_EMAIL,
  CONTACT_PHONE_NUMBER,
} from '../../utils/consts';
import adressIconImg from '../../imgs/adressIcon.png';
import phoneIconImg from '../../imgs/phoneIcon.png';
import mailIconImg from '../../imgs/mailIcon.png';
import './css/ContactsRow.css';
const ContactsRow = () => {
  return (
    <div className="contact-info">
      <div className="adress">
        <img src={adressIconImg} alt="Не удалось загрузить изображение!" />
        <span>
          {/* <p id="info-text">адрес</p> */}
          <p id="info-text">{CONTACT_ADRESS}</p>
        </span>
      </div>
      <div className="phone-number">
        <img src={phoneIconImg} alt="Не удалось загрузить изображение!" />
        <span>
          {/* <p id="info-text">телефон</p> */}
          <p id="info-text">{CONTACT_PHONE_NUMBER}</p>
        </span>
      </div>
      <div className="mail">
        <img src={mailIconImg} alt="Не удалось загрузить изображение!" />
        <span>
          {/* <p id="info-text">почта</p> */}
          <p id="info-text">{CONTACT_EMAIL}</p>
        </span>
      </div>
    </div>
  );
};

export default ContactsRow;
