import logoImg from '../../imgs/logo.png';
import { Link } from 'react-router-dom';
import { MAIN_ROUTE } from '../../utils/consts';
import './css/Logo.css';
const Logo = () => {
  return (
    <div className="logo">
      <Link to={MAIN_ROUTE} id="logo-link">
        <img src={logoImg} alt="Не удалось загрузить изображение!" />
        <span>
          <p>совет ветеранов</p>
          <p id="sub-text">Кинешма</p>
        </span>
      </Link>
    </div>
  );
};

export default Logo;
