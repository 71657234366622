import {
  CONTACTS_ROUTE,
  DOCUMENTS_ROUTE,
  GALLERY_ROUTE,
  INFORMATION_ROUTE,
  MAIN_ROUTE,
  NEWS_ROUTE,
  RECOMMENDATIONS_ROUTE,
  URHELP_ROUTE,
} from '../../utils/consts';

const pagesLink = [
  { 'Главная': MAIN_ROUTE },
  { 'О нас': INFORMATION_ROUTE },
  // { 'Документы': DOCUMENTS_ROUTE },
  // { 'Юрпомощь': URHELP_ROUTE },
  // { 'Советы и Рекомендации': RECOMMENDATIONS_ROUTE },
  { 'Новости': NEWS_ROUTE },
  // { 'Галерея': GALLERY_ROUTE },
  { 'Контакты': CONTACTS_ROUTE },
];

export default pagesLink;
