// import pagesLink from './PagesLink';
import './css/Footer.css';
import pagesLink from './PagesLink';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../..';
import { ADMIN_ROUTE, CONTACT_ADRESS, CONTACT_EMAIL, CONTACT_PHONE_NUMBER } from '../../utils/consts';
import { observer } from 'mobx-react-lite';
import Logo from './Logo';
import ContactsRow from './ContactsRow';
const Footer = () => {
  const { user } = useContext(Context);
  const navigate = useNavigate();
  return (
    <div className="footer-main">
      <div className="footer-main-logo">
        <Logo />
        {user.isAuth ? (
          <div className="admin-buttons">
            <button
              onClick={(e) => navigate(ADMIN_ROUTE)}
              className="button"
              id="admin-button"
            >
              Админ панель
            </button>
            <button
              className="button"
              id="admin-button-logout"
              onClick={() => user.logout()}
            >
              Выйти
            </button>
          </div>
        ) : null}
        <p>
        <p id='adress'>
          <p style={{margin:0}}>155800 г.Кинешма</p>
          {CONTACT_ADRESS}
        </p>
        <p id='mail'>{CONTACT_EMAIL}</p>
        <p id='phone'>{CONTACT_PHONE_NUMBER}</p>
        <p id='fullname'>© 2022. Ивановское региональное отделение Всероссийской общественной организации ветеранов</p>
        </p>
      </div>
      <div className="footer-menu-buttons">
        <h3>Об организации</h3>
        <ul>
          {pagesLink.map((link) => {
            return (
              <li
                key={`${Object.keys(link)}_${
                  link[Object.keys(link)]
                }-${Math.random()}`}
              >
                <Link to={link[Object.keys(link)]}>{Object.keys(link)}</Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="footer-main-schedule">
        <h3>Режим работы</h3>
        {/* <ContactsRow /> */}
        <p>
          <p>Пн-Пт: c 8:00 до 17:00</p>
          <p>Сб-Вс: выходной</p>
        </p>
      </div>
    </div>
  );
};

export default observer(Footer);