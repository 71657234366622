import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Context } from '../../../..';

const DeleteSliderForm = () => {
  const [idInput, setIdInput] = useState('...');
  const { slider } = useContext(Context);
  const deleteNewHandler = (e) => {
    e.preventDefault();
    slider.deleteSlide(idInput);
  };
  return (
    <>
      <h2>Удалить Слайд</h2>
      <select
        id="select-input"
        onChange={(e) => {
          setIdInput(e.target.options[e.target.selectedIndex].id);
        }}
      >
        <option defaultValue id="...">
          ...
        </option>
        {slider._slider.map((el) => {
          return (
            <option key={el.id} id={el.id}>
              {el.text[0].slice(0, 35)}... ({el.type})
            </option>
          );
        })}
      </select>
      <button className="button" onClick={deleteNewHandler}>
        Удалить
      </button>
    </>
  );
};

export default observer(DeleteSliderForm);
