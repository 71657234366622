const ContactCard = ({ title, iconSrc, children }) => {
  const icon = require('../../../imgs/' + iconSrc);
  return (
    <div className="contact-card">
      <div className="contact-card_icon">
        <img src={icon} />
      </div>
      <div className="contact-card_title">
        <h2>{title}</h2>
      </div>
      <div className="contact-card_info">{children}</div>
    </div>
  );
};
export default ContactCard;
