import './css/MainPage.css';
import AboutCompanyMainPage from './pages split components/MainPage/AboutCompanyMainPage';
import HelpfulInfoMainPage from './pages split components/MainPage/HelpfulInfoMainPage';
import PhotoSliderMainPage from './pages split components/MainPage/PhotoSliderMainPage';
import UrHelpMainPage from './pages split components/MainPage/UrHelpMainPage';
import NewsMainPage from './pages split components/MainPage/NewsMainPage';
import { useContext } from 'react';
import { Context } from '..';
const MainPage = () => {
  const { slider, news, helpful } = useContext(Context);
  return (
    <div className="main-page-container page-container">
      {Boolean(slider._slider.length) && <PhotoSliderMainPage />}
      <AboutCompanyMainPage />
      {Boolean(helpful._news.length) && <HelpfulInfoMainPage />}
      {Boolean(news._news.length) && <NewsMainPage />}
      <UrHelpMainPage />
    </div>
  );
};

export default MainPage;
