import { ABOUT_COMPANY_TEXT } from '../utils/consts';
import './css/Information.css';
const Information = () => {
  return (
    <div className="information-page-container page-container">
      <h2>СОВЕТ ВЕТЕРАНОВ Г.КИНЕШМА</h2>
      <p>
        {ABOUT_COMPANY_TEXT}
      </p>
      <p>
        ЦЕЛИ И ПРЕДМЕТ ОРГАНИЗАЦИИ<br/><br/> 2.1. 
        Целями Организации являются: <br/>- защита
        гражданских, социально-экономических, трудовых, личных прав и свобод
        представителей старшего поколения россиян в улучшении социально-бытовых
        условий их жизни, обеспечении их достойного положения в обществе; <br/>-
        формирование в обществе уважительного отношения к старшему поколению
        граждан России; <br/>- содействие в гражданско-патриотическом, военно-
        патриотическом, духовно-нравственном и трудовом воспитании граждан
        Российской Федерации. <br/>2.2. Для выполнения уставных целей Организация
        осуществляет следующие предметы деятельности: <br/>- представляет интересы
        членов Организации в вопросах социальной защиты ветеранов, инвалидов,
        участников локальных и других войн, а также лиц, пострадавших от
        радиационных и иных техногенных катастроф, в вопросах пенсионного
        обеспечения и льгот, установленных ветеранам, пенсионерам и инвалидам; <br/>-
        содействует утверждению в обществе высоких нравственных и духовных
        ценностей, сохранению и обогащению национальных культур народов
        Российской Федерации, привлекает ветеранов к участию в патриотическом
        воспитании молодежи, передаче ей лучших традиций в труде и служении
        Отечеству; <br/>- проводит работу по подготовке резерва кадров Организации; <br/>-
        проявляет заботу об эффективном использовании жизненного опыта ветеранов
        войны и труда; <br/>- содействует достижению гражданского согласия и мира
        между народами, выступая против любых проявлений национализма и
        экстремизма;
        участвует в военно-мемориальной работе по созданию музеев боевой и трудовой славы, надлежащему содержанию воинских захоронений, памятников, обелисков и мемориальных досок;
содействует процессу достижения максимальной адресности социальной помощи ветеранов, пенсионеров, инвалидов;
<br/>-	осуществляет просветительскую деятельность;
организует культурные, спортивные и иные мероприятия, в том числе международные, по целевым направлениям деятельности Организации;
<br/>-	осуществляет взаимодействие с заинтересованными органами государственной власти и органами местного самоуправления, общественными объединениями, религиозными организациями, научными, образовательными, спортивными и иными учреждениями по вопросам деятельности Организации;
<br/>-	способствует	духовно-нравственному, гражданскому и
патриотическому воспитанию граждан Российской Федерации через вовлечение их в социально полезную деятельность, организацию и проведение различных мероприятий;
<br/>-	инициирует,	разрабатывает и реализует международные,
федеральные, региональные и муниципальные программы и проекты, направленные на гражданско-патриотическое, духовно-нравственное и трудовое воспитание граждан Российской Федерации, а также на реализацию целей Организации;
<br/>-	участвует в установленном законом порядке в разработке и реализации законодательных и иных правовых актов федерального, регионального и местного уровней в порядке, предусмотренном действующим законодательством Российской Федерации;
<br/>-	оказывает помощь членам Организации в решении вопросов, связанных с целями Организации;
<br/>-	организует и	проводит обучающие семинары, конференции,
симпозиумы, курсы, лекции, практикумы, мастер-классы и другие
аналогичные мероприятия;
<br/>- оказывает консультативную помощь;
<br/>-	проводит социологические исследования и мониторинги;
<br/>-	осуществляет информационную, издательскую и полиграфическую деятельность в целях просвещения населения;
<br/>-	осуществляет благотворительную деятельность, а также деятельность в области содействия благотворительности и добровольчества.

      </p>
    </div>
  );
};

export default Information;
