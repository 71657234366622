import UrhelpForm from './splitComponents/UrhelpForm';
const UrHelpMainPage = () => {
  return (
    <div className="urhelp container">
      {/* <div> */}
      <h2 className="zag">юридическая помощь</h2>
      {/* <h6>Отправьте заявку и мы рассмотрим её в течение 30 минут.</h6> */}
      {/* </div> */}
      <UrhelpForm />
    </div>
  );
};

export default UrHelpMainPage;
