import {
  collection,
  getDocs,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { makeAutoObservable } from 'mobx';
import { app, db, storage } from '../../config/firebaseConfig';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { v4 } from 'uuid';
import getNowDate from '../modals/getDateFunc';

export default class NewsStore {
  constructor() {
    this._news = [];
    this._urls = [];
    this._isLoading = true;
    this._isUploading = false;
    makeAutoObservable(this);
  }
  get news() {
    return this._news;
  }
  get urls() {
    return this._urls;
  }
  get isUploading() {
    return this._isUploading;
  }
  get isLoading() {
    return this._isLoading;
  }
  setNews(news) {
    this._news = news;
  }
  setUrls(urls) {
    this._urls = urls;
  }
  setIsLoading(bool) {
    this._isLoading = bool;
  }
  setIsUploading(bool) {
    this._isUploading = bool;
  }
  async getAllNews() {
    try {
      const newsCol = collection(db, 'news');
      const newsSnapshot = await getDocs(newsCol);
      const newsList = newsSnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      this.setNews(
        newsList.sort((a, b) => a.createdAt - b.createdAt).reverse()
      );
      this.setIsLoading(false);
      return newsList;
    } catch (e) {
      console.log(e);
    }
  }
  async uploadFile(title, text, date, imagesUpload) {
    // if (
    //   imagesUpload === [] ||
    //   title === '' ||
    //   text === '' ||
    //   date === '' ||
    //   date === null
    // ) {
    //   alert('Не все данные заполнены!');
    //   return;
    // }
    try {
      const imagesFullPathRef = [];
      const img = imagesUpload.map(async (el, index, arr) => {
        const imageRef = ref(storage, `images/news/${v4() + el.name}`);
        imagesFullPathRef.push(imageRef.fullPath);
        const snapshot = await uploadBytes(imageRef, el);
        return await getDownloadURL(snapshot.ref);
      });
      this.setIsLoading(false);
      Promise.all(img).then((imgArr) => {
        this.setNew({
          title,
          text,
          date: getNowDate(new Date(date)).nowDateType2,
          img: imgArr,
          pathToImg: imagesFullPathRef,
          createdAt: new Date(date).getTime(),
        }).then((data) => {
          alert('Новость успешно добавлена!');
          this.setIsUploading(false);
        });
      });
    } catch (e) {
      alert('Не удалось добавить новость!');
      this._isUploading(false);
    }
  }
  async setNew({ title, text, date, img, pathToImg, createdAt }) {
    await addDoc(collection(db, 'news'), {
      title: title,
      text: text,
      date: date,
      img: img,
      pathToImg: pathToImg,
      createdAt: createdAt,
    });
    await this.getAllNews();
  }
  async deleteNew(id) {
    if (id === '...') {
      alert('id новости не выбран!');
      return;
    }
    try {
      const deletingNew = this._news.find((el) => el.id === id);
      deletingNew?.pathToImg?.forEach((el) => {
        const imgRef = ref(storage, el);
        deleteObject(imgRef).catch((error) => {
          alert('Не удается удалить изображения! Попробуем еще...');
          return;
        });
      });
      await deleteDoc(doc(db, 'news', id));
      alert('Новость удалена успешно!');
      await this.getAllNews();
    } catch (e) {
      alert('Не удалось удалить новость!');
    }
  }
  async changeNew(id, title, text, date) {
    if (
      id === '...' ||
      title === '' ||
      text === '' ||
      date === '' ||
      date === null
    ) {
      alert('Не все данные введены!');
      return;
    }
    try {
      const updatingNewRef = doc(db, 'news', id);
      await updateDoc(updatingNewRef, {
        title: title,
        text: text,
        date: getNowDate(new Date(date)).nowDateType2,
        createdAt: new Date(date).getTime(),
      });
      await this.getAllNews();
      alert('Новость изменена успешно!');
    } catch (error) {
      alert('Не удалось изменить новость!');
    }
  }
}
// export const getNews = async (db) => {
//   const newsCol = collection(db, 'news');
//   const newsSnapshot = await getDocs(newsCol);
//   const newsList = newsSnapshot.docs.map((doc) => {
//     return { ...doc.data(), id: doc.id };
//   });
//   return newsList;
// };

// const imagesFullPathRef = [];
// const img = [];
// Array.from(imageUploads).forEach((el, index, arr) => {
//   const imageRef = ref(storage, `images/news/${el.name + v4()}`);
//   uploadBytes(imageRef, el).then((snapshot) => {
//     getDownloadURL(snapshot.ref).then((url) => {
//       img.push(url);
//       imagesFullPathRef.push(imageRef.fullPath);
//     });
//   });
// });
// console.log(imagesFullPathRef);
// console.log(img);
// this.setNew({
//   title,
//   text,
//   date,
//   img,
//   pathToImg: imagesFullPathRef,
// }).then((data) => alert('Новость успешно добавлена!'));
// this.setIsLoading(false);

// текущие запросы
// const imageRef = ref(storage, `images/news/${imageUploads.name + v4()}`);
// uploadBytes(imageRef, imageUploads).then((snapshot) => {
//   getDownloadURL(snapshot.ref).then((url) => {
//     this.setNew({
//       title,
//       text,
//       date,
//       img: url,
//       pathToImg: imageRef.fullPath,
//     });
//     this.setIsLoading(false);
//     alert('Новость добавлена успешно!');
//   });
// });
