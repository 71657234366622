import { useContext, useState } from 'react';
import { Context } from '../../../..';
import getNowDate, {
  formatDateTypeTo,
} from '../../../../components/modals/getDateFunc';

const ChangeSliderForm = () => {
  const { slider } = useContext(Context);
  const [selectedId, setSelectedId] = useState('...');
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const selectedIdHandler = (e) => {
    setSelectedId(e.target.options[e.target.selectedIndex].id);
    if (e.target.options[e.target.selectedIndex].id != '...') {
      setIsSelected(true);
    } else {
      setIsSelected(false);
      clearInputs();
    }
  };
  const clearInputs = () => {
    setText1('');
    setText2('');
    setText3('');
  };
  const fillInputs = (e) => {
    e.preventDefault();
    const currSlide = slider._slider.find((el) => el.id == selectedId);
    setText1(currSlide?.text[0]);
    setText2(currSlide?.text[1]);
    setText3(currSlide?.text[2]);
  };
  const changeNew = (e) => {
    e.preventDefault();
    slider.changeSlide(selectedId, [text1, text2, text3], false, '');
  };
  return (
    <>
      <h2>Изменить Слайд</h2>
      <select id="select-input" onChange={selectedIdHandler}>
        <option defaultValue id="...">
          ...
        </option>
        {slider._slider.map((el) => {
          return (
            <option key={el.id} id={el.id}>
              {el.text[0].slice(0, 50)}... ({el.type})
            </option>
          );
        })}
      </select>
      <button className="button" disabled={!isSelected} onClick={fillInputs}>
        Заполнить старый текст
      </button>
      <h3>Поля</h3>
      <input
        placeholder="Введите новый первый текст..."
        value={text1}
        onChange={(e) => setText1(e.target.value)}
        id="text-input"
        disabled={!isSelected}
      />
      <input
        placeholder="Введите новый второй текст..."
        value={text2}
        onChange={(e) => setText2(e.target.value)}
        id="text-input"
        disabled={!isSelected}
      />
      <input
        placeholder="Введите новый третий текст..."
        value={text3}
        onChange={(e) => setText3(e.target.value)}
        id="text-input"
        disabled={!isSelected}
      />
      <button className="button" disabled={!isSelected} onClick={changeNew}>
        Изменить
      </button>
    </>
  );
};

export default ChangeSliderForm;
