import { Route, Routes } from 'react-router-dom';
import MainPage from '../pages/MainPage';
import NotFoundPage from '../pages/NotFoundPage';
import { authRouters, routers } from '../routes';
import Menu from './Menu/Menu';
import { useContext } from 'react';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';

const AppRouter = () => {
  const { user } = useContext(Context);
  return (
    <Routes>
      <Route path="/" element={<Menu />}>
        <Route index element={<MainPage />} exact />;
        {user.isAuth &&
          authRouters.map(({ path, Component }) => (
            <Route
              key={`${path}_${Math.random()}`}
              path={path}
              element={Component}
              exact
            />
          ))}
        {routers.map(({ path, Component }) => (
          <Route
            key={`${path}_${Math.random()}`}
            path={path}
            element={Component}
            exact
          />
        ))}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default observer(AppRouter);
