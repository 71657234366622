import pagesLink from './PagesLink';
import './css/Header.css';

import { NavLink } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../..';
import Logo from './Logo';
import ContactsRow from './ContactsRow';

const Header = () => {
  const { user } = useContext(Context);
  const toggleMenu = (e) => {
    document.querySelector('.menu-buttons').classList.toggle('open');
    document.querySelector('.toggle-menu-button').classList.toggle('open');
  };
  const menuBtnsHandler = (e) => {
    if (e.target.classList.contains('nav-link')) {
      document.querySelector('.menu-buttons').classList.remove('open');
      document.querySelector('.toggle-menu-button').classList.remove('open');
    }
  };
  return (
    <div className="header-main">
      <div className="info">
        <Logo />
        <ContactsRow />
        <button className="toggle-menu-button" onClick={toggleMenu}></button>
      </div>
      <ul className="menu-buttons" onClick={menuBtnsHandler}>
        {pagesLink.map((link) => (
          <li key={`id-${link[Object.keys(link)]}`}>
            <NavLink to={link[Object.keys(link)]} className="nav-link">
              {Object.keys(link)}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(Header);
