import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { PatternFormat } from 'react-number-format';
import { emailjsAcc } from '../../../../utils/consts';
const UrhelpForm = () => {
  const form = useRef();
  //Состояния
  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('+7 (___)-___-__-__');
  const [mailInput, setMailInput] = useState('');
  const [messageInput, setMessageInput] = useState('');

  function clearInputs() {
    setNameInput('');
    setPhoneInput('');
    setMailInput('');
    setMessageInput('');
  }
  //Обработчики
  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (!phoneInput.trimEnd().endsWith('_')) {
      emailjs
        .sendForm(
          emailjsAcc.serviceId,
          emailjsAcc.emailTempId,
          form.current,
          emailjsAcc.publicKey
        )
        .then((result) => {
          event.target.reset();
          clearInputs();
          alert('Ваше сообщение успешно отправлено!');
        })
        .catch((err) => console.log(err));
    } else {
      alert('Форма не заполнена полностью!');
    }
  };
  const changeNameInputHandler = (event) => {
    setNameInput(event.target.value);
  };
  const changePhoneInputHandler = (event) => {
    setPhoneInput(event.target.value);
    // console.log(phoneInput, !phoneInput.endsWith('_'));
  };
  const changeMailInputHandler = (event) => {
    setMailInput(event.target.value);
  };
  const changeMessageInputHandler = (event) => {
    setMessageInput(event.target.value);
  };
  return (
    <form ref={form} onSubmit={formSubmitHandler}>
      <div className="name-input input">
        <input
          placeholder="имя"
          type="text"
          name="user_name"
          value={nameInput}
          onChange={changeNameInputHandler}
          required
        />
      </div>
      <div className="phone-input input">
        {/* <input
          placeholder="телефон"
          type="number"
          name="user_phone"
          value={phoneInput}
          onChange={changePhoneInputHandler}
          required
        /> */}
        <PatternFormat
          format="+7 (###)-###-##-##"
          allowEmptyFormatting
          mask="_"
          name="user_phone"
          value={phoneInput}
          onChange={changePhoneInputHandler}
          placeholder="телефон"
          required
        />
      </div>
      <div className="mail-input input">
        <input
          placeholder="адрес электронной почты"
          type="email"
          name="user_mail"
          value={mailInput}
          onChange={changeMailInputHandler}
          required
        />
      </div>
      <textarea
        placeholder="сообщение"
        value={messageInput}
        name="message"
        onChange={changeMessageInputHandler}
        required
      />
      <button type="submit" className="button">
        Отправить
      </button>
    </form>
  );
};

function sendMailReq(text) {
  console.log(text);
}

export default UrhelpForm;
