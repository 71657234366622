import { useContext, useState } from 'react';
import { Context } from '../../../..';
const AddSliderForm = () => {
  const { slider } = useContext(Context);
  // const [title, setTitle] = useState('');
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  // const [title, setTitle] = useState('');
  const [type, setType] = useState('...');
  const [hasButton, setHasButton] = useState(false);
  const [buttonUrl, setButtonUrl] = useState('');
  const [imagesUpload, setImageUpload] = useState(null);

  const addNew = (e) => {
    e.preventDefault();
    slider.uploadFile(
      [text1, text2, text3],
      imagesUpload,
      hasButton,
      buttonUrl,
      type
    );
  };
  return (
    <>
      <h2>Добавить Слайд</h2>
      <br />
      <h3>Параметры</h3>
      <select
        id="select-input-for-form"
        onChange={(e) => setType(e.target.options[e.target.selectedIndex].id)}
      >
        <option defaultValue id="...">
          Выберите тип слайда...
        </option>
        {slider._sliderTypes.map((el) => {
          return (
            <option key={el} id={el}>
              {el}
            </option>
          );
        })}
      </select>
      <select
        id="select-input-for-form"
        onChange={(e) =>
          setHasButton(Boolean(+e.target.options[e.target.selectedIndex].id))
        }
      >
        <option defaultValue id={'0'}>
          Нет кнопки
        </option>
        <option id="1">Есть кнопка</option>
      </select>
      <h3>Инфо</h3>
      {hasButton && (
        <>
          <input
            placeholder="Введите ссылку для кнопки..."
            value={buttonUrl}
            onChange={(e) => setButtonUrl(e.target.value)}
            id="text-input"
          />
          <br />
        </>
      )}
      {/* {type != 'герой' && (
        <input
          type="text"
          placeholder="Введите заголовок..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          id="title-input"
        />
      )} */}
      {type === '...' && !hasButton && <h2>Пока никаких полей нет!</h2>}
      {type === 'герой' && (
        <>
          <input
            placeholder="Введите первый текст (имя героя)..."
            value={text1}
            onChange={(e) => setText1(e.target.value)}
            id="text-input"
          />
          <input
            placeholder="Введите второй текст..."
            value={text2}
            onChange={(e) => setText2(e.target.value)}
            id="text-input"
          />
          <input
            placeholder="Введите третий текст..."
            value={text3}
            onChange={(e) => setText3(e.target.value)}
            id="text-input"
          />
          <br />
        </>
      )}
      <h3>Изображение слайда</h3>
      <input
        type="file"
        onChange={(event) => {
          setImageUpload(event.target.files[0]);
        }}
      />
      <button onClick={addNew} className="button">
        Добавить
      </button>
    </>
  );
};

export default AddSliderForm;
