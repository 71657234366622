import { makeAutoObservable } from 'mobx';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
// import AuthService from '../../http/userApi';
// import { API_URL } from '../../http/index';

export default class UserStore {
  constructor() {
    this.user = {};
    this.isAuth = false;
    makeAutoObservable(this);
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setUser(user) {
    this.user = user;
  }

  async login(email, password) {
    try {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          this.setAuth(true);
          this.setUser(user);
        })
        .catch((error) => {
          alert('Ошибка при авторизации!');
        });
    } catch (e) {
      alert('Ошибка при авторизации!');
    }
  }
  async logout() {
    signOut(auth)
      .then(() => {
        this.setAuth(false);
        this.setUser({});
      })
      .catch((error) => {
        alert('Ошибка при авторизации!');
      });
  }
  async checkAuth() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setAuth(true);
        this.setUser(user);
        const uid = user.uid;
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  }

  // async registration(email, password) {
  //   try {
  //     const response = await AuthService.registration(email, password);
  //     // console.log(response);
  //     localStorage.setItem('token', response.data.accessToken);
  //     this.setAuth(true);
  //     this.setUser(response.data.user);
  //   } catch (e) {
  //     console.log(e.response?.data?.message);
  //   }
  // }

  // async logout() {
  //   try {
  //     const response = await AuthService.logout();
  //     localStorage.removeItem('token');
  //     this.setAuth(false);
  //     this.setUser({});
  //   } catch (e) {
  //     console.log(e.response?.data?.message);
  //   }
  // }

  // async checkAuth() {
  //   this.setLoading(true);
  //   try {
  //     const response = await axios.get(`${API_URL}/user/refresh`, {
  //       withCredentials: true,
  //     });
  //     // console.log(response);s
  //     localStorage.setItem('token', response.data.accessToken);
  //     this.setAuth(true);
  //     this.setUser(response.data.user);
  //   } catch (e) {
  //     console.log(e.response?.data?.message);
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }
}

/*
import {IUser} from "../models/IUser";
import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import axios from 'axios';
import {AuthResponse} from "../models/response/AuthResponse";
import {API_URL} from "../http";

export default class Store {
    user = {} as IUser;
    isAuth = false;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUser(user: IUser) {
        this.user = user;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password);
            console.log(response)
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async registration(email: string, password: string) {
        try {
            const response = await AuthService.registration(email, password);
            console.log(response)
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({} as IUser);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async checkAuth() {
        this.setLoading(true);
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true})
            console.log(response);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
        } finally {
            this.setLoading(false);
        }
    }
}
 */
