import {
  collection,
  getDocs,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { makeAutoObservable } from 'mobx';
import { app, db } from '../../config/firebaseConfig';

export default class HelpfulStore {
  constructor() {
    this._news = [];
    this._isHelpfulLoading = true;
    this._isUploading = false;
    makeAutoObservable(this);
  }
  get news() {
    return this._news;
  }
  get isUploading() {
    return this._isUploading;
  }
  get isHelpfulLoading() {
    return this._isHelpfulLoading;
  }
  setNews(news) {
    this._news = news;
  }
  setIsLoading(bool) {
    this._isHelpfulLoading = bool;
  }
  setIsUploading(bool) {
    this._isUploading = bool;
  }
  async getAllNews() {
    try {
      const newsCol = collection(db, 'helpful');
      const newsSnapshot = await getDocs(newsCol);
      const newsList = newsSnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      this.setNews(
        newsList.sort((a, b) => a.createdAt - b.createdAt).reverse()
      );
      this.setIsLoading(false);
      return newsList;
    } catch (e) {
      console.log(e);
    }
  }
  async uploadFile(name, link) {
    // if (name === '' || link === '') {
    //   alert('Не все данные заполнены!');
    //   return;
    // }
    try {
      this.setIsLoading(false);
      this.setNew({
        name,
        link,
      }).then((data) => {
        alert('Ссылка успешно добавлена!');
        this.setIsUploading(false);
      });
    } catch (e) {
      alert('Не удалось добавить ссылку!');
      this._isUploading(false);
    }
  }
  async setNew({ name, link }) {
    await addDoc(collection(db, 'helpful'), {
      link: link,
      name: name,
    });
    await this.getAllNews();
  }
  async deleteNew(id) {
    if (id === '...') {
      alert('id ссылки не выбран!');
      return;
    }
    try {
      await deleteDoc(doc(db, 'helpful', id));
      alert('Ссылка удалена успешно!');
      await this.getAllNews();
    } catch (e) {
      alert('Не удалось удалить ссылку!');
    }
  }
  async changeNew(id, name, link) {
    if (id === '...' || name === '' || link === '') {
      alert('Не все данные введены!');
      return;
    }
    try {
      const updatingNewRef = doc(db, 'helpful', id);
      await updateDoc(updatingNewRef, {
        link: link,
        name: name,
      });
      await this.getAllNews();
      alert('Ссылка изменена успешно!');
    } catch (error) {
      alert('Не удалось изменить ссылку!');
    }
  }
}
