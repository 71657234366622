import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Context } from '../../../../';

const DeleteNewForm = () => {
  const [idInput, setIdInput] = useState('...');
  const [isSelected, setIsSelected] = useState(false);
  const { news } = useContext(Context);
  const deleteNewHandler = (e) => {
    e.preventDefault();
    news.deleteNew(idInput);
  };
  const selectedIdHandler = (e) => {
    setIdInput(e.target.options[e.target.selectedIndex].id);
    if (e.target.options[e.target.selectedIndex].id != '...') {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };
  return (
    <>
      <h2>Удалить Новость</h2>
      <select id="select-input" onChange={selectedIdHandler}>
        <option defaultValue id="...">
          ...
        </option>
        {news._news.map((el) => {
          return (
            <option key={el.id} id={el.id}>
              {`(${el.date}) ${el.title.slice(0, 50)}`}...
            </option>
          );
        })}
      </select>
      <button
        className="button"
        onClick={deleteNewHandler}
        disabled={!isSelected}
      >
        Удалить
      </button>
    </>
  );
};

export default observer(DeleteNewForm);

/* <input
  type="text"
  placeholder="Введите id новости..."
  value={idInput}
  onChange={(e) => setIdInput(e.target.value)}
  id="id-input"
/> */
