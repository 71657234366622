import './css/AdminLoginPage.css';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '../index';
import { ADMIN_ROUTE } from '../utils/consts';
import { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';

const AdminLoginPage = () => {
  const { user } = useContext(Context);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const navigate = useNavigate();
  if (user.isAuth) {
    return (
      <div className="page-container">
        <h3>Вы успешно авторизовались!</h3>
        <button className="button">
          <Link to={ADMIN_ROUTE}>Админ панель</Link>
        </button>
        <button className="button" onClick={() => user.logout()}>
          Выйти
        </button>
      </div>
    );
  }

  const loginHandler = (e) => {
    e.preventDefault();
    user.login(email, password);
  };
  return (
    <>
      <form
        className="gaggug-login-page-container page-container"
        onSubmit={loginHandler}
      >
        <input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="text"
          placeholder="Логин"
        />
        <input
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
          placeholder="Пароль"
        />
        <button type="submit" className="button login-form-button">
          Войти
        </button>
      </form>
    </>
  );
};

export default observer(AdminLoginPage);
