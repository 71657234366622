import {
  COMPANY_DIRECTOR,
  COMPANY_REGISTRATION_DATE,
  CONTACT_EMAIL,
  CONTACT_PHONE_NUMBER,
  FULL_COMPANY_NAME,
  INN_COMPANY,
  KPP_COMPANY,
  OGRN_COMPANY,
} from '../utils/consts';
import './css/Contacts.css';
import ContactCard from './pages split components/Contacts/ContanctCard';
const Contacts = () => {
  return (
    <div className="contact-page-container page-container">
      <h1>Наши реквизиты</h1>
      <div className="card-list">
        <ContactCard
          title={'Общие вопросы'}
          iconSrc={'contactPageMessageIcon.png'}
        >
          <div className="cas-quest">
            <div>
              <p>Телефон</p>
              <p>{CONTACT_PHONE_NUMBER}</p>
              <p>{COMPANY_DIRECTOR}</p>
            </div>
            <div>
              <p>Электронная почта</p>
              <p>{CONTACT_EMAIL}</p>
            </div>
          </div>
        </ContactCard>
        <ContactCard
          title={'Фактический адрес'}
          iconSrc={'contactPageAddressIcon.png'}
        >
          <div>
            <p>
              155800,Ивановская область,
              <br />
              г.Кинешма,ул. Им Фрунзе,д3
            </p>
            <button className="button-outline">Карта</button>
          </div>
        </ContactCard>
        <ContactCard title={'Документы'} iconSrc={'contactPageMailIcon.png'}>
          <div className="cas-quest">
            {/* <div>
              <p>Полное юр. наименование</p>
              <p>{FULL_COMPANY_NAME}</p>
            </div> */}
            <div>
              <p>ИНН / KПП / ОГРН</p>
              <p>{`${INN_COMPANY} / ${KPP_COMPANY} / ${OGRN_COMPANY}`}</p>
            </div>
            <div>
              <p>Руководитель</p>
              <p>{COMPANY_DIRECTOR}</p>
            </div>
            <div>
              <p>Дата регистрации</p>
              <p>{COMPANY_REGISTRATION_DATE}</p>
            </div>
          </div>
        </ContactCard>
      </div>
    </div>
  );
};

export default Contacts;
