import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import UserStore from './components/store/UserStore';
import NewsStore from './components/store/NewsStore';
import SliderStore from './components/store/SliderStore';
import HelpfulStore from './components/store/HelpfulStore';

export const Context = createContext(null);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider
    value={{
      news: new NewsStore(),
      slider: new SliderStore(),
      user: new UserStore(),
      helpful: new HelpfulStore(),
    }}
  >
    <App />
  </Context.Provider>
);
