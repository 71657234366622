//! Роуты
export const MAIN_ROUTE = '/';
export const INFORMATION_ROUTE = '/information';
export const DOCUMENTS_ROUTE = '/docs';
export const URHELP_ROUTE = '/urhelp';
export const RECOMMENDATIONS_ROUTE = '/recommendations';
export const NEWS_ROUTE = '/news';
export const GALLERY_ROUTE = '/gallery';
export const CONTACTS_ROUTE = '/contacts';
export const ADMIN_ROUTE = '/admin';
export const ADMIN_LOGIN_ROUTE = '/login';
export const ADMIN_NEWS_CONTROLLER_ROUTE = ADMIN_ROUTE + '/news-controller';
export const ADMIN_SLIDER_CONTROLLER_ROUTE = ADMIN_ROUTE + '/slider-controller';
export const ADMIN_HELPFUL_CONTROLLER_ROUTE =
  ADMIN_ROUTE + '/helpful-controller';

//! Информация на навигационной панели
export const CONTACT_PHONE_NUMBER = '+8(49331)5-57-22';
export const CONTACT_ADRESS = 'ул.Фрунзе,3';
export const CONTACT_EMAIL = 'vyacheslav.zakharov.1955@mail.ru';

export const INN_COMPANY = '3703006957';
export const KPP_COMPANY = '370301001';
export const OGRN_COMPANY = '1033700024049';
export const COMPANY_REGISTRATION_DATE = '08.02.2003';
export const COMPANY_DIRECTOR = 'Вячеслав Николаевич Захаров';
export const FULL_COMPANY_NAME =
  'Кинешемская городская организация всероссийская общественной организации ветеранов (пенсионеров), войны, труда, вооруженных сил и правоохранительных органов';

//!О Совете
export const ABOUT_COMPANY_TEXT = `Совет ветеранов городского округа Кинешмы был создан 1983 году. Совет ветеранов призван решать задачи по защите прав и интересов ветеранов,
обеспечению достойного положения их в обществе, участию в общественно-политической жизни и работе по воспитания подрастающего поколения.
Оказание содействия по вопросам защиты конституционных прав ветеранов войны и труда, пенсионеров, ветеранов Вооруженных Сил и правоохранительных органов.
Содействие развитию сферы льготного бытового и торгового обслуживания ветеранов (пенсионеров).
Содействие организации досуга и отдыха ветеранов (пенсионеров).
Содействие организации и развитию медико-социальной помощи ветеранам (пенсионерам).
`;

//! ПочтаСендер
export const emailjsAcc = {
  serviceId: 'service_8lgnowg',
  emailTempId: 'template_lebgbur',
  publicKey: 'MTIl-lzh9sSbtXgfq',
};

export const urhelpPageList = [
  {
    question: 'Как быстро и бесплатно узнать баланс карты?',
    answer: 'В ВТБ Онлайн. На первом же экране после входа.',
  },
  {
    question: 'Как получить ПИН код к новой карте?',
    answer:
      'По телефону или в ВТБ Онлайн. Также вы можете скачать бесплатное мобильное приложение ВТБ-Онлайн в AppStore или Google Play.',
  },
  {
    question: 'Мне сложно запомнить ПИН-код. Могу я записать его где-нибудь?',
    answer: 'Нет, записывать не рекомендуется.',
  },
  {
    question: 'Для чего нужна магнитная полоса на карте?',
    answer:
      'Эта полоса содержит вашу персональную информацию. Храните карту бережно, чтобы не повредить полосу.',
  },
  {
    question:
      'Может ли сотрудник магазина, где я расплатился картой, скопировать данные с магнитной полосы?',
    answer:
      'Такие случаи мошенничества возможны. Поэтому мы рекомендуем вам не терять карту из вида, когда вы передаете ее для оплаты (особенно в кафе и ресторанах).',
  },
  {
    question:
      'Что делать, если я потерял карту? Кто-нибудь может снять с нее деньги?',
    answer:
      'Карта — это еще не наличные, поэтому паниковать не стоит. Если вы оперативно заблокируете пропавшую карту, то с вашими деньгами ничего не случится.',
  },
  {
    question: 'Можно ли использовать карту за границей?',
    answer:
      'Да, конечно! Карту очень удобно брать с собой в путешествие, ведь с ней вам не придется беспокоиться о сохранности наличных или их конвертации в местную валюту или декларировании при пересечении границы.',
  },
  {
    question:
      'Я часто оплачиваю покупки через Интернет. Что нужно делать, чтобы защитить себя от мошенничества?',
    answer:
      'При оплате товаров через Интернет требуется специальный трехзначный код CVV2/CVC2.',
  },
  {
    question:
      'Сейчас столько ограблений через банкоматы. Где безопасно снимать наличные?',
    answer:
      'Конечно, максимально безопасный способ получения наличных — это «родной» банкомат вашего банка.',
  },
  {
    question:
      'Переносится ли дата платежа, если приходится на нерабочий / праздничный день?',
    answer:
      'В соответствии с требованиями законодательства РФ и условиями договора использования банковских карт.',
  },
  {
    question: 'Как увеличить кредитный лимит по карте?',
    answer:
      'Для увеличения кредитного лимита необходимо обратиться в любой офис.',
  },
  {
    question: 'Как найти ближайший банкомат?',
    answer:
      'Уточнить адрес ближайшего банкомата вы можете на сайте банка и в мобильном приложении ВТБ Онлайн.',
  },
  {
    question: 'Как перевыпустить карту, если истекает ее срок действия?',
    answer:
      'Карта действительна до последнего дня месяца, указанного на лицевой стороне.',
  },
  {
    question: 'Сколько стоит снятие наличных?',
    answer:
      'Стоимость снятия наличных определяется комиссией, установленной в тарифах.',
  },
  {
    question: 'Как выпустить дополнительную карту?',
    answer:
      'Для выпуска дополнительной карты на свое имя необходимо обратиться в любое отделение банка с паспортом.',
  },
  {
    question: 'Каков срок рассмотрения заявки на кредитную карту?',
    answer:
      'Срок рассмотрения заявки — до 5 рабочих дней. О принятом решении вы будете уведомлены с помощью СМС-сообщения или по контактному номеру телефона сотрудником банка.',
  },
];
