import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../..';
import Loader from '../Loader';
import { observer } from 'mobx-react-lite';

const Menu = (props) => {
  const { news, slider, helpful } = useContext(Context);
  return (
    <>
      <Header />

      {news._isLoading &&
        slider._isSliderLoading &&
        helpful._isHelpfulLoading && <Loader />}
      {!news._isLoading &&
        !slider._isSliderLoading &&
        !helpful._isHelpfulLoading && <Outlet />}

      <Footer />
    </>
  );
};

export default observer(Menu);
