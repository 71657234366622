import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTE } from '../utils/consts';
import AddHelpfulForm from './pages split components/AdminControllers/AdminHelpfulController/AddHelpfulForm';
import DeleteHelpfulForm from './pages split components/AdminControllers/AdminHelpfulController/DeleteHelpfulForm';
import ChangeHelpfulForm from './pages split components/AdminControllers/AdminHelpfulController/ChangeHelpfulForm';
import BackToButton from '../components/Buttons/BackToButton';

const AdminHelpfulControllerPage = () => {
  const [selectedOption, setSelectedOption] = useState(0);

  return (
    <div className="admin-helpful-controller-container controller-container page-container">
      <BackToButton link={ADMIN_ROUTE} />
      <div>
        <form>
          <select
            id="select-input"
            onChange={(e) => {
              setSelectedOption(e.target.selectedIndex);
            }}
          >
            <option defaultValue id="add-helpful">
              Добавить Ссылку
            </option>
            <option id="delete-helpful">Удалить Ссылку</option>
            <option id="change-helpful">Изменить Ссылку</option>
          </select>
          {selectedOption == 0 ? (
            <AddHelpfulForm />
          ) : selectedOption == 1 ? (
            <DeleteHelpfulForm />
          ) : (
            <ChangeHelpfulForm />
          )}
        </form>
      </div>
    </div>
  );
};
export default AdminHelpfulControllerPage;
// e.target.options[e.target.selectedIndex].id
