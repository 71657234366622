import './css/NewPage.css';
import { Link, useLocation } from 'react-router-dom';
import { useContext, useState } from 'react';
import { Context } from '../..';
import CustomModal from '../../components/modals/CustomModal';
import { NEWS_ROUTE } from '../../utils/consts';
import NotFoundPage from '../NotFoundPage';
import { observer } from 'mobx-react-lite';
import NextToButton from '../../components/Buttons/NextToButton';
import BackToButton from '../../components/Buttons/BackToButton';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/bundle';
import 'swiper/css/autoplay';

const NewPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentNewImg, setCurrentNewImg] = useState('');
  const { news } = useContext(Context);
  const location = useLocation();
  const newId = location.pathname.split('/')[2];
  const currentNew = news._news.find((newOne) => newOne.id == newId);
  if (!currentNew) {
    return <NotFoundPage />;
  }
  return (
    <div className="new-page page-container">
      <BackToButton link={NEWS_ROUTE}/>
      {/* <NextToButton link={NEWS_ROUTE} array={news._news} currEl={currentNew} /> */}
      <h1>{currentNew.title}</h1>
        <div className='img-list'>
          {currentNew.img.map((url) => {
          return (
            <img
              key={url}
              src={url}
              alt="Не удалось загрузить изображение!"
              onClick={() => {
                setCurrentNewImg(url);
                setShowModal(true);
              }}
            />
          );
        })}
          </div>
      <div className="photo-slider container">
        <Swiper
        slidesPerView={1}
        spaceBetween={50}
        loop={true}
        autoplay={{
          delay: 4500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="slider"
      >
        {currentNew.img.map(
          (url) => {
            return (
              <SwiperSlide key={`${url}`}>
                <div className={`content`} id={`index_${url}`}>
                <img
              key={url}
              src={url}
              alt="Не удалось загрузить изображение!"
              // onClick={() => {
              //   setCurrentNewImg(url);
              //   setShowModal(true);
              // }}
            />
                </div>
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
      </div>
      <p>{currentNew.text}</p>
      <span id="date">{currentNew.date}</span>
      <CustomModal onClose={() => setShowModal(false)} show={showModal}>
        <img
          src={currentNewImg}
          alt="Не удалось загрузить изображение!"
          width="60%"
          style={{ maxHeight: '500px' }}
        />
      </CustomModal>
      {/* <AlertModal
        onClose={() => setShowModal(false)}
        show={showModal}
        title="Внимание!"
      >
        asdadad
      </AlertModal> */}
    </div>
  );
};
//         src={require(`../../imgs/bdimgs/news/${currentNew.img}`)}
export default observer(NewPage);
